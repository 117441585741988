<template>
  <div class="table_wrapper">
    <v-card flat tile>
      <v-toolbar class="rounded">
        <v-btn icon :to="'/faq'">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>

        <v-toolbar-title>FAQ New</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn color="primary" v-on:click="saveAllData" :loading="loading" dark>
          <v-icon>mdi-check</v-icon>
          Save
        </v-btn>
      </v-toolbar>

      <v-layout class="p-5" wrap row>
        <v-col cols="12">
          <v-text-field
            label="General Title For Admin Panel List"
            v-model="pageTitle"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-tabs
            v-model="tab"
            dark
            class="rounded"
            background-color="teal darken-3"
            show-arrows
            align-with-title
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab v-for="(item, index) in itemData" :key="index">
              {{ item.lang.name }}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(item, index) in itemData" :key="'k-' + index">
              <v-card color="basil" flat>
                <v-text-field
                  label="Question Title"
                  v-model="item.question.title"
                  :class="item.lang.id == 'ar' ? 'text-right' : ''"
                ></v-text-field>
                <v-card elevation="0">
                  <v-card-title
                    >Answers & Contents
                    <v-btn
                      icon
                      fab
                      class="ml-3"
                      small
                      v-on:click="
                        item.question.answers.push({
                          title: '',
                          html: '',
                          slug: '',
                        })
                      "
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn></v-card-title
                  >
                  <v-card
                    elevation="0"
                    class="rounded mb-5"
                    v-for="(ans, index) in item.question.answers"
                    :key="index"
                  >
                    <v-toolbar color="teal" dark flat>
                      <v-toolbar-title
                        >{{ index + 1 }} - Answer <v-spacer></v-spacer>
                      </v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn
                        icon
                        fab
                        v-if="index > 0"
                        v-on:click="item.question.answers.splice(index, 1)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </v-toolbar>

                    <v-card flat class="px-5">
                      <v-text-field
                        label="Answer Title"
                        v-model="ans.title"
                        :class="item.lang.id == 'ar' ? 'text-right' : ''"
                      ></v-text-field>
                      <v-text-field
                        label="Answer Slug"
                        v-model="ans.slug"
                        :class="item.lang.id == 'ar' ? 'text-right' : ''"
                      ></v-text-field>
                      <vue-editor
                        :class="item.lang.id == 'ar' ? 'rtl' : 'ltr'"
                        v-model="ans.html"
                        :editorOptions="editorSettings"
                        use-custom-image-handler
                        @image-added="handleImageAdded"
                      ></vue-editor>
                    </v-card>
                  </v-card>
                </v-card>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-layout>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { VueEditor } from "vue2-editor";

import Quill from "quill";
import ImageResize from "quill-image-resize-vue";

Quill.register("modules/imageResize", ImageResize);

export default {
  name: "FaqEdit",
  components: {
    VueEditor,
  },
  data: () => ({
    tab: null,
    loading: true,
    menu: false,
    token: localStorage.getItem("authToken"),
    tabs: [],
    itemData: [],
    pageTitle: "",
    editorSettings: {
      modules: {
        imageResize: {},
      },
    },
  }),
  methods: {
    handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
      var formData = new FormData();
      formData.append("file", file);

      axios
        .post(process.env.VUE_APP_API_URL + "upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((result) => {
          const url = result.data.file_paths[0]; // Get url from response
          Editor.insertEmbed(cursorLocation, "image", url);
          resetUploader();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    saveAllData: function() {
      this.loading = true;

      if (this.$route.params.id) {
        const dataForm = {
          title: this.pageTitle,
          content: this.itemData,
          id: this.$route.params.id,
        };

        axios
          .post(process.env.VUE_APP_API_URL + "faq/update", dataForm)
          .then((res) => {
            this.loading = false;
            this.$router.push({
              name: "Faq",
            });
          })
          .catch((err) => console.log(err));
      } else {
        const dataForm = {
          title: this.pageTitle,
          content: this.itemData,
          id: null,
        };

        axios
          .post(process.env.VUE_APP_API_URL + "faq/save", dataForm)
          .then((res) => {
            this.loading = false;
            this.$router.push({
              name: "Faq",
            });
          })
          .catch((err) => console.log(err));
      }
    },
    getData: function(id) {
      let url = process.env.VUE_APP_API_URL + "faq/show/" + id;
      axios
        .get(url)
        .then((res) => {
          this.loading = false;

          this.itemData = res.data.data.content;
          this.pageTitle = res.data.data.title;
        })
        .catch((err) => console.log(err));
    },

    getLang: function() {
      let url =
        process.env.VUE_APP_API_URL +
        "language/filter?page=1&per_page=1000&api_token=" +
        this.token;
      axios
        .post(url)
        .then((res) => {
          res.data.data.data.forEach((item) => {
            let obj = {
              lang: {
                id: item.id,
                name: item.name,
              },
              question: {
                title: "",
                answers: [
                  {
                    title: "",
                    html: "",
                    slug: "",
                  },
                ],
              },
            };
            this.itemData.push(obj);
          });
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.getData(this.$route.params.id);
    } else {
      this.getLang();
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
};
</script>

<style>
.quillWrapper {
  position: relative;
}
.quillWrapper.rtl {
  text-align: right;
}
.quillWrapper.rtl #quill-container .ql-editor {
  text-align: right;
  direction: rtl;
}
.text-right .v-input__slot {
  direction: rtl;
  text-align: right;
}
.text-right .v-label {
  left: auto !important;
  right: 0px !important;
}
.text-right .v-label--active {
  transform: translateY(-18px) translateX(23px) scale(0.75) !important;
}
</style>
